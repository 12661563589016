import React from 'react';
import '../../../../assets/styles/coachmag.css';
import { loadCSS } from '../../../../utils/loadCSS';

loadCSS('coachmag-site');

export const CoachmagSiteWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>;
};
export default CoachmagSiteWrapper;
